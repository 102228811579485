@import '/assets/App/scss/vars.scss';

/*.QuantityInput {
  text-align: center;
  height: 100%;
  width: 100px !important;
  border: 2px solid #333 !important;
  border-radius: 10px !important;

  &:focus {
    border-color: $colorBrand !important;
    outline: none;
  }
}

@media (min-width: $mediaBreakpointXL) {
  .PromoPriceContainer {
    order: 2;
  }
  .FlavorContainer {
    order: 1;
  }
}*/

.SizeRow {
  .SizeNameAboveImage {
    @media (min-width: 2000px) {
      display: none;
    }
  }
  .SizeNameAbovePromo {
    padding-bottom: 10px;
    display: none;
    @media (min-width: 2000px) {
      display: block;
    }
  }
  .SizePromo {
    background-color: $colorRed;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    font-style: italic;
  }
  .SizePriceReference {
    padding-top: 20px;
    text-align: right;
    color: #aaaaaa;
    text-decoration: line-through;
    font-size: 1.2rem;
    font-weight: bold;
    font-style: italic;
    span {
      font-size: 1rem;
    }
  }
  .SizePriceDiscounted {
    text-align: right;
    font-size: 2.5rem;
    color: #ff0000;
    font-weight: 900;
    font-style: italic;
    span {
      font-size: 1rem;
      color: #ffffff;
    }

    @media (max-width: $mediaBreakpointMD) {
      font-size: 2rem;
    }
  }

  .SizeFlavoursTable {
    width: 100%;
    .SizeFlavoursTableRow {
      .SizeFlavoursTableQty {
        border-radius: 8px;
        border: none;
        text-align: center;
      }
    }
    .SizeFlavoursDividerRow {
      &:last-child {
        display: none;
      }
    }
  }
}

.SizeDividerRow {
  &:last-child {
    display: none;
  }
}
