@import 'assets/App/scss/vars.scss';

.ScrollDownAnimationCotainer {
  cursor: pointer;
  height: 3.125rem;
  width: 3.125rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ScrolldownArrows {
  position: relative;
  top: 12px;
  width: 48px;
  height: 48px;
}

.ScrolldownArrow {
  background: url(https://demo.l2banners.ru/res/scrolldown/scrolldown.svg)
  no-repeat;
  width: 48px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: scale(0.3) translateY(-30px);
}

.ScrolldownArrow1 {
  animation: ScrolldownArrow 3s ease-out infinite;
}

.ScrolldownArrow2 {
  animation: ScrolldownArrow 3s ease-out 1s infinite;
}

.ScrolldownArrow3 {
  animation: ScrolldownArrow 3s ease-out 2s infinite;
}

@keyframes ScrolldownArrow {
  33.3% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  66.6% {
    opacity: 1;
    transform: scale(1) translateY(12px);
  }
  100% {
    opacity: 0;
    transform: scale(0.5) translateY(40px);
  }
}