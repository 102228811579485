@import 'assets/App/scss/vars.scss';

@media (prefers-reduced-motion: no-preference) {
  @mixin commonAnimationRightHidden {
    opacity: 0;
    transition: all 1200ms ease-in-out;
    transform: translate3d(50%, 0, 0);
  }
  @mixin commonAnimationRightVisible {
    opacity: 1;
    transition: all 1200ms ease-in-out;
    transform: translate3d(0, 0, 0);
  }
  @mixin commonAnimationLeftHidden {
    opacity: 0;
    transition: all 1000ms ease-in-out;
    transform: translate3d(-50%, 0, 0);
    filter: blur(0.5rem);
  }
  @mixin commonAnimationLeftVisible {
    opacity: 1;
    transition: all 1000ms ease-in-out;
    transform: translate3d(0, 0, 0);
    filter: blur(0);
  }

  @mixin commonAnimationExploreVisible {
    transform: translate3d(0, 0, 0);
    transition: all 1200ms ease;
    opacity: 1;
    filter: blur(0);
  }

  .VisiblePageImg {
    scale: 1;
    opacity: 1;
    filter: blur(0);
    transition: all 800ms ease;
  }

  .HiddenPageImg {
    scale: 0.7;
    opacity: 0.5;
    filter: blur(1rem);
    transition: all 800ms ease;
  }

  .VisiblePageTitle {
    @include commonAnimationLeftVisible;
  }
  .HiddenPageTitle {
    @include commonAnimationLeftHidden;
  }

  .VisibleDate {
    @include commonAnimationRightVisible;
  }

  .HiddenDate {
    @include commonAnimationRightHidden;
  }

  .VisiblePageDesc {
    @include commonAnimationRightVisible;
  }
  .HiddenPageDesc {
    @include commonAnimationRightHidden;
  }

  .VisibleCalendarBtn {
    @include commonAnimationLeftVisible;
  }
  .HiddenCalendarBtn {
    @include commonAnimationLeftHidden;
  }

  .AddToCalendarButton {
    transition: transform 400ms ease-in-out !important;

    &:hover {
      transform: scale(1.05);
      transition: transform 400ms ease-in-out;
    }
  }

  .ImgCardsParent {
    &:nth-child(1).VisibleImgCards {
      transition-delay: 600ms;
    }
    &:nth-child(2).VisibleImgCards {
      transition-delay: 400ms;
    }
    &:nth-child(3).VisibleImgCards {
      transition-delay: 200ms;
    }
  }

  .HiddenImgCards {
    opacity: 0;
    transition: all 300ms ease;
    filter: blur(5px);
    transform: translate3d(-100%, 0, 0);
  }

  .VisibleImgCards {
    opacity: 1;
    transition: all 1s ease;
    filter: blur(0);
    transform: translate3d(0, 0, 0);
  }

  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

  .AnimationConicBorder {
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      padding: 51%;
      translate: -50% -50%;
      z-index: -1;
      border-radius: 10px;
      background-image: conic-gradient(
        from var(--angle),
        #ff4545,
        #00ff99,
        #006aff,
        #ff0095,
        #ff4545
      );
    }

    &[data-speed='5']::after,
    &[data-speed='5']::before {
      animation: spinAnimation linear infinite;
      animation-duration: 5s;
    }
    &[data-speed='2']::after,
    &[data-speed='2']::before {
      animation: spinAnimation linear infinite;
      animation-duration: 2s;
    }
    &[data-speed='3.5']::after,
    &[data-speed='3.5']::before {
      animation: spinAnimation linear infinite;
      animation-duration: 3.5s;
    }

    @keyframes spinAnimation {
      to {
        --angle: 360deg;
      }
    }
  }

  .VisibleExploreDesc {
    @include commonAnimationExploreVisible;
  }
  .HiddenExploreDesc {
    transform: translate3d(-50%, 0, 0);
    transition: all 0 ease;
    opacity: 0;
    filter: blur(2rem);
  }

  .HiddenExploreImg {
    transform: translate3d(50%, 0, 0);
    transition: all 0 ease;
    opacity: 0;
    filter: blur(2rem);
  }

  .VisibleExploreImg {
    @include commonAnimationExploreVisible;
  }
}
