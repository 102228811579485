@import '/assets/App/scss/vars.scss';

.UlProductList {
  list-style: none;
  overflow: auto;
}
@media screen and (min-width: $mediaBreakpointXL) {
  .UlProductList {
    height: 52%;
    max-height: 350px;
  }
}

.SizeDividerRow {
  &:last-child {
    display: none;
  }
}
