@import 'assets/App/scss/vars.scss';

.CustomCursor {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  color: #fff;
  border-radius: 50%;
  border: 3px solid #fff;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.CustomCursor.IsHovering {
  display: flex;
}
.CustomCursor.NotHovering {
  display: none;
}

// hide scrollbar for safari
.NewcomerWeekHtml::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.NewcomerWeekHtml {
  -webkit-scroll-snap-type: y mandatory;
  scroll-snap-type: y mandatory;
}

.ArrowContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 10;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
