@import '/assets/App/scss/vars.scss';
@import '/assets/css/shop/vars.scss';

.NcwHomeBGImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 63%;
  z-index: -1;
  background-size: cover;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  background-color: $colorNcw;
}

.Images {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  width: 100%;
}

.Images:hover {
  transform: scale(1.02);
  filter: brightness(1.1);
}

.DefaultFont {
  font-family: $fontDefault;
}

.NewcomerWeekDate {
  text-transform: uppercase;
  font-family: newcomer-week, Arial, sans-serif;
  position: absolute;
  z-index: 3;
  top: 14%;
  left: 45%;
  color: #000;
  transform: rotate(-10deg);
  padding: 5px;
  background-color: #19eb19;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  width: 200px !important;
  height: 40px;
  overflow: hidden;
}

.NcwFaqs {
  font-size: 2rem;
  font-weight: bold;
  color: $colorBrand;
}