@import '/assets/App/scss/vars.scss';

.SampleBoxModal {
  overflow: hidden !important;
  
  > div {
    top: 50%;
    transform: translate(-50%, -50%);

    > div {
      max-width: 1200px;
      background-color: $colorNcw;
      transform: translate(-50%, -50%);
      left: 50%;

      // modal header
      > div {
      }
    }
  }

  .BoxImgContainer {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
