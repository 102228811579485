.inheritColor {
  color: inherit;
}

.opacityOnHover:hover {
  color: inherit;
  opacity: 0.6;
}

.underline {
  text-decoration: underline;
}

.noUnderline {
  text-decoration: inherit;
}
