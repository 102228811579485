@import '../../../../scss/vars.scss';

.GuidedTourContainer {
  background-color: $colorWhite;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid $colorBlack;
  max-width: 350px;
  min-width: 330px;
  height: auto;

  button {
    margin-left: 7px;
  }
}

.ShowConsentCard {
  visibility: visible;
}
.HideConsentCard {
  visibility: hidden;
}
