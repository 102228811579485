@import '/assets/App/scss/vars.scss';

.CategoriesContainer {
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
  gap: 10px;
  padding: 20px 20px;
}

@media (min-width: $mediaBreakpointXL) {
  .CategoriesContainer {
    max-width: 92%;
  }
}

.CategoryItemBox {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 150px;
  //height: 80px;
  //font-size: 1.2rem;
  //font-weight: bold;
  //text-transform: uppercase;
  //background: linear-gradient(135deg, #e0e0e0, #f9f9f9);
  background-color: #333333;
  color: #dddddd;
  font-style: italic;
  border-radius: 4px;
  //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;
  border: none;
  padding: 2px 8px;

  &:hover {
    background-color: #666666;
    //background: linear-gradient(135deg, $colorBrand, #2f6992);
    //transform: translateY(-5px);
    //box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    //color: #fff !important;
  }

  &:active {
    //transform: scale(0.5);
  }

  &:focus {
    outline: none;
    //box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.4);
  }
}
